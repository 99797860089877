<template>
    <!-- 交易记录 -->
	<div class="container">
        <template v-if="isMobile==0">
            <div class="header">
                <router-link class="btn" to="/stocks">{{$t('aside.list')[1]}}</router-link>
                <i class="iconfont2 icon-right_arrow"></i>
                <span>{{$t('common.transactionRecord')}}</span>
            </div>
            <div class="content">
                <div class="head">
                    <!-- 交易所交易对 -->
                    <div class="item">
                        <div class="title">{{$t('common.selectOpen')}}</div>
                        <div class="select" @click="popCollection = true">
                            <input v-model="open" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                            <i class="el-icon-arrow-down"></i>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.selectdate')}}</div>
                        <el-date-picker
                            v-if="isSmall=='normal'"
                            :editable="false"
                            class="datetime"
                            v-model="dateValue"
                            type="datetimerange"
                            range-separator="-"
                            :start-placeholder="$t('common.startdete')"
                            :end-placeholder="$t('common.enddete')"
                            value-format="timestamp"
                            :clearable="false"
                            @change="selectTime"
                            :picker-options="isDisabled"
                        />
                        <!-- 如果小屏分为两个日期时间选择器 -->
                        <div class="datetime_box" v-else>
                            <el-date-picker
                                :editable="false"
                                v-model="start_time"
                                class="datetime_item left"
                                type="datetime"
                                :placeholder="$t('common.startdete')"
                                value-format="timestamp"
                                :picker-options="maxTime"
                                :clearable="false">
                            </el-date-picker>
                            <span class="line">-</span>
                            <el-date-picker
                                :editable="false"
                                v-model="end_time"
                                class="datetime_item right"
                                type="datetime"
                                :placeholder="$t('common.enddete')"
                                value-format="timestamp"
                                :picker-options="minTime"
                                :clearable="false">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.tradingDirection')}}</div>
                        <el-select class="inp" v-model="direction" :placeholder="$t('common.select')">
                            <el-option
                                :label="$t('common.whole')"
                                value="2">
                            </el-option>
                            <el-option
                                :label="$t('common.purchase')"
                                value="1">
                            </el-option>
                            <el-option
                                :label="$t('common.sellout')"
                                value="0">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.address')}}</div>
                        <div class="text">
                            <el-input
                                class="inp"
                                :placeholder="$t('common.address')"
                                v-model="address"
                            />
                        </div>
                        <!-- <el-select class="inp" v-model="address" :placeholder="$t('common.select')">
                            <el-option
                                :label="$t('common.address')"
                                :value="0">
                            </el-option>
                        </el-select> -->
                    </div>
                    <div class="btn" @click="handleSearch">{{$t('common.query')}}</div>
                </div>

                <div class="cont">
                    <div class="caption">
                        {{$t('common.transactionRecord')}}
                        <span class="tip">({{$t('tip.recordListTip')}})</span>
                    </div>
                    <div class="cont_box">
                        <div class="hd">
                            <span class="address">{{$t('common.tradingAddress')}}</span>
                            <!-- <span class="pair">{{$t('common.pair')}}</span> -->
                            <span class="direction">{{$t('common.tradingDirection')}}</span>
                            <!-- <span class="average">{{$t('common.transactionAverage')}}</span> -->
                            <span class="num">{{$t('common.transactionNum')}}</span>
                            <!-- <span class="amount">{{$t('common.transactionAmount')}}</span> -->
                            <span class="gas">{{$t('common.gas')}}</span>
                            <span class="state">{{$t('common.orderStatus')}}</span>
                            <span class="time">{{$t('common.time')}}</span>
                            <span class="operation">{{$t('common.viewTX')}}</span>
                        </div>
                        <div class="bd">
                            <template v-if="recordList.length">
                                <div class="list"
                                v-infinite-scroll="load"
                                :infinite-scroll-distance="10"
                                :infinite-scroll-delay="400"
                                :infinite-scroll-disabled="load_disabled">
                                    <div class="item" v-for="(item,index) in recordList" :key="index">
                                        <span class="address">
                                            <el-tooltip effect="dark" :content="$t('subManage.copyAddress')" placement="top" :enterable="false">
                                                <i class="iconfont2 icon-jilu1 copyBtn" @click="handleCopy(item.wallet_address)"></i>
                                            </el-tooltip>
                                            <el-tooltip effect="dark" :content="item.wallet_address" placement="top" :enterable="false">
                                                <span>{{addressFormat(item.wallet_address)}}</span>
                                            </el-tooltip>
                                        </span>
                                        <!-- <span class="pair">{{item.pair}}</span> -->
                                        <span class="direction" :class="{red:item.trade_type=='0'}">{{
                                            item.trade_type=='0'? $t('common.sellout'):   
                                            item.trade_type=='1'? $t('common.purchase'):item.trade_type
                                        }}</span>
                                        <!-- <span class="average">
                                            {{item.price}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.price_unit}}</span>
                                        </span> -->
                                        <span class="num">
                                            {{item.amount}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.amount_unit}}</span>
                                        </span>
                                        <span class="gas">
                                            {{item.gas_use}} 
                                            <span class="unit gas_unit">{{item.gas_unit}}</span>
                                        </span>
                                        <!-- <span class="amount">{{item.price*item.amount}}</span> -->
                                        <span class="state">{{
                                            item.state==0? $t('common.orderStatus1'):
                                            item.state==1? $t('common.orderStatus2'):
                                            item.state==2? $t('common.orderStatus3'):item.state
                                        }}</span>
                                        <span class="time">{{item.order_time}}</span>
                                        <span class="operation">
                                            <img :src="require('@/assets/images/chain/'+chainIcon)" alt="" @click="jumpOut(item.order_id)">
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <el-empty v-else :image="emptyImg" :image-size="120" />
                        </div>
                    </div>
                </div>

            </div>
        </template>
		<template v-else-if="isMobile==1">
            <div class="content_phone">
                <div class="head">
                    <el-input
                        class="search"
                        :placeholder="$t('common.address')"
                        prefix-icon="el-icon-search"
                        v-model="address"
                        type="search"
                        @change="handleSearch2('keywords')"
                    />
                </div>

                <div class="cont">
                    <div class="caption">
                        {{$t('common.transactionRecord')}}
                        <span class="tip">({{$t('tip.recordListTip')}})</span>
                    </div>
                    <div class="cont_box">
                            <template v-if="recordList.length">
                                <div class="list"
                                v-infinite-scroll="load"
                                :infinite-scroll-distance="10"
                                :infinite-scroll-delay="400"
                                :infinite-scroll-disabled="load_disabled">
                                    <div class="item" v-for="(item,index) in recordList" :key="index">
                                        <div>
                                            <div class="label">{{$t('common.tradingAddress')}}</div>
                                            <div class="value">
                                                {{item.wallet_address}}
                                            </div>
                                        </div>
                                        <div>
                                            <div class="label">{{$t('common.tradingDirection')}}</div>
                                            <div class="value" :class="item.trade_type=='0'?'red':'green'">{{
                                                item.trade_type=='0'? $t('common.sellout'):   
                                                item.trade_type=='1'? $t('common.purchase'):item.trade_type
                                            }}</div>
                                        </div>
                                        <!-- <div>
                                            <div class="label">{{$t('common.transactionAverage')}}</div>
                                            <div class="value">
                                                {{item.price}} 
                                                <span class="unit" :class="item.trade_type=='0'?'red':'green'">{{item.price_unit}}</span>
                                            </div>
                                        </div> -->
                                        <div>
                                            <div class="label">{{$t('common.transactionNum')}}</div>
                                            <div class="value">
                                                {{item.amount}} 
                                                <span class="unit" :class="item.trade_type=='0'?'red':'green'">{{item.amount_unit}}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="label">{{$t('common.gas')}}</div>
                                            <div class="value">
                                                {{item.gas_use}} 
                                                <span class="unit gas_unit">{{item.gas_unit}}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="label">{{$t('common.orderStatus')}}</div>
                                            <div class="value">{{
                                                item.state==0? $t('common.orderStatus1'):
                                                item.state==1? $t('common.orderStatus2'):
                                                item.state==2? $t('common.orderStatus3'):item.state
                                            }}</div>
                                        </div>
                                        <div>
                                            <div class="label">{{$t('common.time')}}</div>
                                            <div class="value">
                                                {{item.order_time}}
                                            </div>
                                        </div>
                                        <div class="viewTX">
                                            <div class="label">{{$t('common.viewTX')}}</div>
                                            <div class="value">
                                                <img :src="require('@/assets/images/chain/'+chainIcon)" alt="" @click="jumpOut(item.order_id)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <el-empty v-else :image="emptyImg" :image-size="120" />
                        <!-- <div class="hd">
                            <span class="address">{{$t('common.tradingAddress')}}</span>
                            <span class="time">{{$t('common.time')}}</span>
                            <span class="direction">{{$t('common.tradingDirection')}}</span>
                            <span class="average">{{$t('common.transactionAverage')}}</span>
                            <span class="num">{{$t('common.transactionNum')}}</span>
                            <span class="state">{{$t('common.orderStatus')}}</span>
                        </div>
                        <div class="bd">
                            <template v-if="recordList.length">
                                <div class="list"
                                v-infinite-scroll="load"
                                :infinite-scroll-distance="10"
                                :infinite-scroll-delay="400"
                                :infinite-scroll-disabled="load_disabled">
                                    <div class="item" v-for="(item,index) in recordList" :key="index">
                                        <span class="address">
                                            <el-tooltip :content="item.wallet_address" placement="top">
                                                <span>{{addressFormat(item.wallet_address)}}</span>
                                            </el-tooltip>
                                        </span>
                                        <span class="time">{{item.order_time}}</span>
                                        <span class="direction" :class="{red:item.trade_type=='0'}">{{
                                            item.trade_type=='0'? $t('common.sellout'):   
                                            item.trade_type=='1'? $t('common.purchase'):item.trade_type
                                        }}</span>
                                        <span class="average">
                                            {{item.price}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.price_unit}}</span>
                                        </span>
                                        <span class="num">
                                            {{item.amount}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.amount_unit}}</span>
                                        </span>
                                        <span class="state">{{
                                            item.state==0? $t('common.orderStatus1'):
                                            item.state==1? $t('common.orderStatus2'):
                                            item.state==2? $t('common.orderStatus3'):item.state
                                        }}</span>
                                    </div>
                                </div>
                            </template>
                            <el-empty v-else :image="emptyImg" :image-size="120" />
                        </div> -->
                    </div>
                </div>
            </div>
        </template>

		<!-- 选择盘口 常用盘口 -->
		<el-dialog class="collect" :title="$t('common.selectOpen')" center :visible.sync="popCollection" :show-close="false">
			<div class="title">{{$t('common.commonly')}}</div>
			<div class="company_box">
				<div class="tab_title">
					<div class="left">{{$t('common.exchange')}}</div>
					<div class="right">{{$t('common.pair')}}</div>
				</div>
				<div class="item" v-for="(item,index) in collectList" :key="index" @click="commonlyTc(item)">
					<div class="left">{{item.exchange_name}}</div>
					<div class="right">
						<div>{{item.currency}}</div>
						<div class="collect_btn" @click.stop="collectCancel(item.currency,item.web_id)">
							<img src="@/assets/images/collection_active.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="title">{{$t('stocks.manual')}}</div>
			<div class="manual_box">
				<div class="select" @click="popExchange = true">
					<input v-model="popexchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
					<i class="iconfont icon-down"></i>
				</div>
				<div class="pair">
					<input v-model="popyenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
					<i class="split"></i>
					<input v-model="popyenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
				</div>
			</div>
			<div class="foot">
				<span class="g_btn btn" @click="popCollection=false">{{$t('common.back')}}</span>
				<span class="g_btn btn submit" @click="handleInConfirm">{{$t('common.confirm')}}</span>
			</div>
		</el-dialog>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
        
		<!-- 移动端-筛选条件弹窗 -->
        <van-action-sheet 
        v-model="filter_show" 
        :title="$t('common.filter')"
        :closeable="false">
			<div class="filter_form">
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select" @click="popExchange = true">
							<input v-model="vant_exchange_value" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.pair')}}</div>
					<div class="inp_box">
						<input v-model="vant_yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
						<i class="split"></i>
						<input v-model="vant_yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.startdete')}}</div>
					<div class="text" @click="openDateTime('start')">
						<input v-model="vant_start_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.enddete')}}</div>
					<div class="text" @click="openDateTime('end')">
						<input v-model="vant_end_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
                <div class="item">
                    <div class="title">{{$t('common.tradingDirection')}}</div>
					<div class="text">
						<div class="select" @click="directionShow = true">
							<input v-model="direction_name" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
                </div>
				<div class="foot">
					<div class="g_btn btn" @click="closeAction">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleSearch2">{{$t('common.query')}}</div>
				</div>

			</div>
        </van-action-sheet>
		<!-- 移动端-时间选择器 -->
		<van-popup v-model="dateTimeBox" round position="bottom">
			<van-datetime-picker
			ref="datetimePicker"
			v-model="currentDate"
			type="datetime"
        	:title="$t('common.selectTime')"
			:formatter="formatter"
			visible-item-count="4"
			@confirm="setTime"
			:min-date="min_date"
			:max-date="max_date"
			/>
		</van-popup>
        <!-- 移动端-交易方向选择 -->
        <div class="direction">
            <van-action-sheet v-model="directionShow" :actions="actions" @select="directionSelect" />
        </div>
    </div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'record.styl';
</style>

<script>
import Clipboard from 'clipboard';
import { Loading } from 'element-ui';
import ExchangeDialog from '@/components/exchange_not_el.vue';
import { mapGetters } from 'vuex';
import bus from "../bus.js";
import timeFormat from '@/utils/methApi/timeFormat'
import addressFormat from '@/utils/methApi/addressFormat'
// api
import {
    getDexOrders,
} from '@/api/markets'
export default ({
    components: {
        ExchangeDialog,
    },
    data() {
        return {
			emptyImg: require('@/assets/images/loading.png'),
            dateValue: [],
            start_time:'',
            end_time:'',
            // 地址
            address:'',
            // 交易方向
            direction:'',

            // 交易记录列表
            recordList:[],
            chain:'',
            chain_url:'',

            // 交易所选择
            popExchange: false,
            web: localStorage.getItem('web') || 'bian',
            exchangeValue: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
            yenUnit1: localStorage.getItem('yenUnit1') || '',
            yenUnit2: localStorage.getItem('yenUnit2') || '',

            // 常用盘口
            popCollection:false,
            // collectList:[],
            popexchange: localStorage.getItem('exchange') || (localStorage.getItem('lang')=='zh'?'币安':'bian'),
            popweb: localStorage.getItem('web') || 'bian',
            popyenUnit1: localStorage.getItem('yenUnit1') || 'btc',
            popyenUnit2: localStorage.getItem('yenUnit2') || 'usdt',
            popweb_id: localStorage.getItem('web_id')||'5',

            page:1,
			load_disabled:false,
            
            // PC-无限滚动-固化变量
			pc_dateValue:[],
            pc_start_time:'',
            pc_end_time:'',
            pc_web:'',
            pc_yenUnit1:'',
            pc_yenUnit2:'',
            pc_direction:'',
			pc_address:'',
            
            // 移动端
            filter_show:false,
            vant_start_time:'',
            vant_end_time:'',
            vant_exchange_value:'',
            vant_web:'',
            vant_yenUnit1:'',
            vant_yenUnit2:'',
            //时间选择器
            dateTimeBox:false,
            currentDate:new Date(),
            type:'',//时间类型 开始时间/结束时间
            // 交易方向选择弹窗
            directionShow:false,
            //交易方向下拉
            actions:[
                {name:this.$t('common.whole'),value:2},
                {name:this.$t('common.purchase'),value:1},
                {name:this.$t('common.sellout'),value:0},
            ],
        }
    },
    computed:{
        ...mapGetters(['account','isSmall','collectList','isMobile']),
        // 盘口信息
        open(){
            return this.exchangeValue+'     '+this.yenUnit1.toUpperCase()+'/'+this.yenUnit2.toUpperCase()
        },
        // 查看交易icon
        chainIcon(){
            if(this.chain=='bsc'){
                return 'bsc.webp'
            }else if(this.chain=='eth'){
                return 'eth.jpg'
            }else if(this.chain=='fantom'){
                return 'fantom.png'
            }else if(this.chain=='heco'){
                return 'heco.svg'
            }else if(this.chain=='matic'){
                return 'matic.webp'
            }else if(this.chain=='metis'){
                return 'metis.png'
            }else if(this.chain=='oec'){
                return 'oec.png'
            }else if(this.chain=='trx'){
                return 'trx.png'
            }
        },
        // 最小时间
        isDisabled(){
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                },
            }
        },
		// 开始时间
        maxTime() {
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    if (this.end_time) { // 如果结束时间不为空，则小于结束时间
                        return this.end_time < time.getTime()||time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }else{
                        return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                }
            }
        },
        // 结束时间
        minTime() {
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    if (this.start_time) { // 如果开始时间不为空，则结束时间大于等于开始时间
                        return this.start_time> time.getTime()||time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                    else {
                    	return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                }
            }
        },

        // 移动端
        min_date(){
            var min_date
            var now=new Date()
            if(this.type=='start'){
                min_date=new Date(now.getTime()-3*30*24*60*60*1000)
            }else{
                min_date=this.vant_start_time?new Date(this.vant_start_time.replace(/\-/g,'/')):new Date(now.getTime()-3*30*24*60*60*1000)
            }
            return min_date
        },
        max_date(){
            var max_date
            if(this.type=='start'){
                max_date=this.vant_end_time?new Date(this.vant_end_time.replace(/\-/g,'/')):new Date()
            }else{
                max_date=new Date()
            }
            return max_date
        },
        // 交易方向名称
        direction_name(){
            try{
                this.actions.forEach(item=>{
                    if(item.value==this.direction){
                        throw(item.name)
                    }
                })
            }
            catch(name){
                return name
            }
        },
    },
    created(){
        // PC-无限滚动-固化变量
        this.pc_dateValue=this.dateValue;
        this.pc_start_time=this.start_time;
        this.pc_end_time=this.end_time;
        this.pc_web=this.web;
        this.pc_yenUnit1=this.yenUnit1;
        this.pc_yenUnit2=this.yenUnit2;
        this.pc_direction=this.direction;
        this.pc_address=this.address;
        // 获取交易记录列表
        this.getRecord()

        // 获取收藏列表
        this.getCollectList()

        // 筛选弹窗-移动端判定
        bus.$on('filter',()=>{
            this.openFilter()
        })
    },
    methods: {
        // 查看交易
        jumpOut(id){
            window.open(this.chain_url+id,'_blank')
        },
        // 复制地址
        handleCopy(code) {
            let clipboard = new Clipboard('.copyBtn', {
                text: () => {
                    return code
                }
            })
            clipboard.on('success', () => {
                this.$message({
                    message: this.$t('tip.copysuccess'),
                    type: 'success',
                    center: true,
                    customClass: 'shotMsg',
                });
                clipboard.destroy();
            })
            clipboard.on('error', () => {
                this.$message({
                    message: this.$t('tip.copyerr'),
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
                clipboard.destroy();
            })
        },
        // 加载更多
        load(){
            // console.log('get-load')
            this.page++
            this.getRecord();
        },
        // 获取交易记录列表
        getRecord(){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });
			var start_time,end_time;
            if(this.isSmall=='small'){
                // 日期没问题 时间选择时 结束时间比开始时间小
                if(this.pc_start_time>this.pc_end_time){
                    start_time=this.pc_end_time?timeFormat(this.pc_end_time,'dateTime'):'';
                    end_time=this.pc_start_time?timeFormat(this.pc_start_time,'dateTime'):'';
                }else{
                    start_time=this.pc_start_time?timeFormat(this.pc_start_time,'dateTime'):'';
                    end_time=this.pc_end_time?timeFormat(this.pc_end_time,'dateTime'):'';
                }
            }else{
                if(this.pc_dateValue[0]>this.pc_dateValue[1]){
                    start_time=this.pc_dateValue&&this.pc_dateValue[1]?timeFormat(this.pc_dateValue[1],'dateTime'):'';
                    end_time=this.pc_dateValue&&this.pc_dateValue[0]?timeFormat(this.pc_dateValue[0],'dateTime'):'';
                }else{
                    start_time=this.pc_dateValue&&this.pc_dateValue[0]?timeFormat(this.pc_dateValue[0],'dateTime'):'';
                    end_time=this.pc_dateValue&&this.pc_dateValue[1]?timeFormat(this.pc_dateValue[1],'dateTime'):'';
                }
            }
            getDexOrders({
                web:this.pc_web,
                account:this.account,
                currency:this.pc_yenUnit1+'_'+this.pc_yenUnit2,
                page:this.page,
                start_time:start_time,
                end_time:end_time,
                wallet_address:this.pc_address,
                trade_type:this.pc_direction
            }).then(res=>{
                // console.log(res)
                // this.recordList=res
                this.chain=res.chain;
                this.chain_url=res.chain_url;
                if(!!res&&!!res.orders&&res.orders.length>0){
                    this.recordList.push(...res.orders);
                }else{
                    this.load_disabled=true;
                }
                loading.close();
            }).catch(err=>{
                loading.close();
            })
        },
        // 钱包地址格式化
        addressFormat(address){
            return addressFormat(address)
        },
        // 获取收藏列表
        getCollectList(){
			this.$store.dispatch('markets/getCollectList');
            // getCollectList({
            //     account:this.account
            // }).then(res=>{
            //     this.collectList=res;
            // })
        },
        // 取消收藏
        collectCancel(currency,web_id){
            collectCancel({
                account:this.account,
                currency,
                web_id
            }).then(res=>{
                this.$message({
                    message: this.$t('tip.cancelCollectionSuc'),
                    type: 'success',
                    center: true,
                    customClass: 'shotMsg',
                });
                this.getCollectList()
            })
        },
        // 常用填充
        commonlyTc(item){
            this.popweb=item.web;
            this.popyenUnit1=item.currency.split('_')[0];
            this.popyenUnit2=item.currency.split('_')[1];
            this.popexchange=item.exchange_name;
            this.popweb_id=item.web_id;
            this.handleInConfirm()
        },
        // 确认进入盘口
        handleInConfirm(){
            // 非空验证
            if(!this.popweb){
                this.$message({
                    message:this.$t('tip.selectExchange')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else if(!this.popyenUnit1||!this.popyenUnit2){
                this.$message({
                    message:this.$t('tip.enterPair')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }else{
                this.exchangeValue=this.popexchange
                this.web=this.popweb
                this.yenUnit1=this.popyenUnit1
                this.yenUnit2=this.popyenUnit2
                this.popCollection=false;
            }
        },
        // 时间日期选择区间
        selectTime(values){
            if(values){
                this.start_time=values[0]
                this.end_time=values[1]
            }
        },
        handleSearch() {
            // 初始化
            this.page=1;
            this.load_disabled=false;
            this.recordList=[];

            // PC-无限滚动-固化变量
			this.pc_dateValue=this.dateValue;
            this.pc_start_time=this.start_time;
            this.pc_end_time=this.end_time;
            this.pc_web=this.web;
            this.pc_yenUnit1=this.yenUnit1;
            this.pc_yenUnit2=this.yenUnit2;
            this.pc_direction=this.direction;
			this.pc_address=this.address;
            
            // 搜索
            this.getRecord();
        },
        // 交易所
        closeExchange() {
            this.popExchange = false;
        },
        handleSelect(data) {
            this.exchangeValue = data.text;
            this.web = data.web;
            this.popExchange = false;
        },

        // 打开筛选弹窗 - 移动端
        openFilter(){
            this.vant_exchange_value=this.exchangeValue
            this.vant_web=this.web
            this.vant_yenUnit1=this.yenUnit1
            this.vant_yenUnit2=this.yenUnit2
            this.vant_start_time=this.start_time
            this.vant_end_time=this.end_time
            
            this.filter_show=true
        },
        // 关闭筛选弹窗
        closeAction() {
            this.filter_show = false;
            // this.$nextTick(()=>{
            // 	this.form = {};
            // 	this.activeItem = null;
            // 	this.web=null;
            // })
        },
        // 打开时间选择器
        openDateTime(type){
            this.type=type
            if(type=='start'){
                this.currentDate=this.start_time||new Date()
            }else{
                this.currentDate=this.end_time||new Date()
            }
            this.dateTimeBox=true;
        },
        // 时间选择器视图
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            if (type === 'hour') {
                return val + '时';
            }
            if (type === 'minute') {
                return val + '分';
            }
            return val;
        },
        setTime(e){
            if(this.type=='start'){
                this.vant_start_time=timeFormat(e,'dateTime')
            }else{
                this.vant_end_time=timeFormat(e,'dateTime')
            }
            this.dateTimeBox=false
        },
        // 移动端-筛选确定
        handleSearch2(type) {
            if(type!='keywords'){
                this.start_time=this.vant_start_time
                this.end_time=this.vant_end_time
                this.exchangeValue=this.vant_exchange_value
                this.web=this.vant_web
                this.yenUnit1=this.vant_yenUnit1
                this.yenUnit2=this.vant_yenUnit2
            }
            // 初始化
            this.page=1;
            this.load_disabled=false;
            this.logList=[];
            this.isFirst='0';
            this.filter_show=false
            // 搜索
            this.getRecord2();
        },
        // 移动端-获取记录
        getRecord2(){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });

            var start_time,end_time;
            if(this.start_time&&this.end_time){
                var dateValue1=new Date(this.start_time?.replace(/\-/g,'/')).getTime()
                var dateValue2=new Date(this.end_time?.replace(/\-/g,'/')).getTime()
                if(dateValue1>dateValue2){
                    start_time=this.end_time;
                    end_time=this.start_time;
                }else{
                    start_time=this.start_time
                    end_time=this.end_time
                }
            }else{
                start_time=this.start_time
                end_time=this.end_time
            }

            getDexOrders({
                web:this.web,
                account:this.account,
                currency:this.yenUnit1+'_'+this.yenUnit2,
                page:this.page,
                start_time:start_time,
                end_time:end_time,
                wallet_address:this.address,
                trade_type:this.direction
            }).then(res=>{
                // console.log(res)
                this.chain=res.chain;
                this.chain_url=res.chain_url;
                if(!!res&&!!res.orders&&res.orders.length>0){
                    this.logList.push(...res.orders);
                    // this.logList=res
                }else{
                    this.load_disabled=true;
                }
                loading.close();
            }).catch(err=>{
                loading.close();
            })
        },
        // 移动端-交易方向选择
        directionSelect(item){
            this.directionShow = false;
            this.direction=item.value
        },
    }
})
</script>